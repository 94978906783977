























import { Component, Vue, Ref } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ListItemBox from '@/components/organisms/ListItemBox.vue'
import ModalTeacherInput, { ModalParams } from '@/components/organisms/ModalTeacherInput.vue'
import { TEACHER_SETTING_TEXT } from '@/constants'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    ButtonBase,
    ListItemBox,
    ModalTeacherInput,
  },
})
export default class ClassName extends Vue {
  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
  private breadcrumbs = [
    { text: this.isV3 ? TEACHER_SETTING_TEXT : '設定', href: '/teacher/setting/top' },
    { text: '集団クラス名', active: true },
  ]

  private classNameDatas: {
    id: number
    name: string
  }[] = []

  @Ref() modalTeacherInput!: ModalTeacherInput

  private modalInfo: ModalParams = {}

  private showModalInputAdd() {
    this.modalInfo = {
      title: '集団クラス名追加',
      buttonName: '追加',
      value: '',
      maxLength: 20,
      onClick: this.create,
    }
    this.modalTeacherInput.show()
  }

  private showModalInputEdit(index: number) {
    this.modalInfo = {
      id: this.classNameDatas[index].id,
      title: '集団クラス名編集',
      buttonName: '保存',
      value: this.classNameDatas[index].name,
      maxLength: 20,
      onClick: this.update,
    }
    this.modalTeacherInput.show()
  }

  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private mounted() {
    this.loadDatas()
  }

  private async loadDatas() {
    await Vue.prototype.$http.httpWithToken.get(`/group_classes?branchId=${this.branchId}`).then((res: any) => {
      this.classNameDatas = res.data.groupClasses.map((groupClass: { id: number; name: string }) => {
        return {
          id: groupClass.id,
          name: groupClass.name,
        }
      })
    })
  }

  private async update(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .put(`/group_classes/${this.modalInfo.id}`, { name: this.modalInfo.value })
      .then(() => {
        this.modalTeacherInput.hide()
        this.loadDatas()
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('集団クラス名が重複しているため登録できません。')
        } else if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }

  private async create(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .post('/group_classes', { branchId: this.branchId, name: this.modalInfo.value })
      .then(() => {
        this.modalTeacherInput.hide()
        this.loadDatas()
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('集団クラス名が重複しているため登録できません。')
        }
      })
  }

  private async destroy(id: number): Promise<void> {
    if (!confirm('削除しますか？')) return

    await Vue.prototype.$http.httpWithToken
      .delete(`/group_classes/${id}`)
      .then(() => {
        this.loadDatas()
      })
      .catch((error: any) => {
        if (error.response.data.status === 422) {
          alert('授業が紐付いているため削除できません。')
        } else if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }
}
