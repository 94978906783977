










import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
  },
})
export default class ListItemBox extends Vue {
  @Prop()
  buttons?: []

  @Prop()
  width!: string

  @Prop()
  minWidth!: string

  @Prop()
  params!: any

  @Prop({ default: null })
  onClickDeleteBtn?: Function

  get styles(): { [key: string]: string } {
    return {
      '--width': this.width,
      '--min-width': this.minWidth,
    }
  }

  private checkclick(func: any): any | null {
    if (func) {
      return func()
    }
    return null
  }

  private onClickDelete(params: any) {
    if (this.onClickDeleteBtn) this.onClickDeleteBtn(params.id)
  }
}
